import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";

const WeekTitle = styled.div`
    display: inline-block;
    font-size: 75px;
    font-weight: bold;
    font-family: 'Inconsolata', monospace;
    width: 100%;
    margin: 25px 0px;
    white-space: nowrap;
    color: var(--terminalGreen);
    filter: blur(0.03em);
    &::after {
        content: attr(data-week-num);
        text-align: left;
        white-space: pre-wrap;
        color: var(--terminalGreen);
        font-size: 250px;
        position: absolute;
        top: 10px;
        left: 130px;
        margin-right: 50%;
        transform:scale(1.4);
        opacity: 0.05;
        line-height: 0.7;
    }
`

const WeekTitleText = styled.span`
    border-right: 10px solid #23fcc7;
    padding-right: 5px;
    animation: CursorBlink 1s step-end infinite, CoolFlicker 2.6s infinite linear;
`

const WeekOverviewTitle = props => (
    <>
        <Helmet>
            <title>{props.tabTitle}</title>
        </Helmet>
        <WeekTitle data-week-num={props.weeknum}>
            <WeekTitleText>{props.titleText}</WeekTitleText>
        </WeekTitle>
    </>
)

export default WeekOverviewTitle;