import React from "react";

import { NavBox, NavTitle, Day, StyledLink, TopicBox, ExternalLink } from "./rightNavComponents";

const WeekThreeNav = props => (
    <NavBox>
        <NavTitle />
        <Day>
            <StyledLink location="/week3/day1" name="Day 1" />
            <TopicBox>
                <ExternalLink type="lecture" name="Intro To Web Dev" link="https://docs.google.com/presentation/d/1DuogPxo7f6exIBeeS9C8KxWXaGFh4XPG3t3OFQExh7s/edit#slide=id.p" />
                <ExternalLink type="lecture" name="HTML + CSS" link="https://docs.google.com/presentation/d/1TO-X6gymWj-wlDOuysqml_xRn2-Kbk30kwI5wnuWP-U/edit#slide=id.p" />
            </TopicBox>
        </Day>
        <Day>
            <StyledLink location="/week3/day2" name="Day 2" />
            <TopicBox>
                <ExternalLink type="lecture" name="Block vs Inline" link="https://docs.google.com/presentation/d/1XR5lRJEQAC2Qd91UU2Jegw8sa2k5g-NjlXOsgAli0aw/edit#slide=id.p" />
            </TopicBox>
        </Day>
        <Day>
            <StyledLink location="/week3/day3" name="Day 3" />
            <TopicBox>
                <ExternalLink type="lecture" name="Box Model  + Flexbox" link="https://docs.google.com/presentation/d/1FrkEB1Qdv6fC8polysEpOMjDoO8HXVrFug1wuvs1n54/edit#slide=id.p" />
            </TopicBox>
        </Day>
        <Day>
            <StyledLink location="/week3/day4" name="Day 4" />
            <TopicBox>
                {/* <ExternalLink type="" name="" link="" /> */}
            </TopicBox>
        </Day>
        <Day>
            <StyledLink location="/week3/day5" name="Day 5" />
            <TopicBox>
                <ExternalLink type="project" name="Design Your Personal Website" link="https://docs.google.com/document/d/1KJhR6nNv7nWVv2pFcORzwN7OUZhiLEoao4ubTlKzmD0/edit" />
                <ExternalLink type="project" name="Build Your Personal Website" link="https://docs.google.com/document/d/1KJhR6nNv7nWVv2pFcORzwN7OUZhiLEoao4ubTlKzmD0/edit" />
            </TopicBox>
        </Day>
    </NavBox>
)

export default WeekThreeNav;