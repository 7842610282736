import React from "react";
import styled from "@emotion/styled";

const LinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 25px;
`;


const LinkBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    & > a {
        font-size: 24px;
        z-index: 1;
    }
    & > h1 {
        font-size: 42px;
        color: var(--terminalGreen);
    }
`;

const ExternalLink = props => (
    <a css={{color: (props.type === 'project' ? '#dd404d' : props.type === 'lecture' ? '#A285DC' : props.type === "coreskill" ? '#51c1e9' : '#FFF' ) }} href={props.link} target="_blank" rel="noopener noreferrer" rek="noopener noreferrer">{props.name}</a>
)


export { LinkContainer, LinkBox, ExternalLink };