import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from "../components/layout.js";
import WeekThreeNav from "../components/rightnavs/weekthreeRnav.js";
import WeekOverviewTitle from "../components/schedule_components/weektitle.js";
import { LinkContainer, LinkBox, ExternalLink } from "../components/schedule_components/weeklinks.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DayScheduleWrapper = makeShortcode("DayScheduleWrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout rightnav={<WeekThreeNav mdxType="WeekThreeNav" />} mdxType="Layout">
    <DayScheduleWrapper mdxType="DayScheduleWrapper">
        <WeekOverviewTitle weeknum={`Week 03`} titleText="Intro To Web Dev" tabTitle={`Week 03`} mdxType="WeekOverviewTitle" />
        <LinkContainer mdxType="LinkContainer">
            <LinkBox mdxType="LinkBox">
                <h1>Projects</h1>
                <ExternalLink type="project" name="HTML + CSS" link="https://docs.google.com/document/d/1bjr1ZkT_OYaaLXj48XqRChet93GRHZteKKGW1RxNDvg/edit" mdxType="ExternalLink" />
                <ExternalLink type="project" name="Ugly HTML" link="https://docs.google.com/document/d/14Gm1mn-HmPkTaDYVXD4_-nyY19ao3dR0NCW3ryM7Mbo/edit" mdxType="ExternalLink" />
                <ExternalLink type="project" name="Copy That Website" link="https://docs.google.com/document/d/1i9y8QxEaqwSOpC3W2_vLrmwxB8M3pZS9HlRQUWWRgkQ/edit" mdxType="ExternalLink" />
                <ExternalLink type="project" name="Layout" link="https://docs.google.com/document/d/1G5uhhICBU0tZkkom21T0IzJxy6Vqraqi5hP8j-0uFcY/edit" mdxType="ExternalLink" />
                <ExternalLink type="project" name="Hired by Super Stellar Games" link="https://docs.google.com/document/d/1Tt444Y3xJdWUUj717XW0q-djaOkJNpKmuwXaYvwWcag/edit" mdxType="ExternalLink" />
                <ExternalLink type="project" name="Design Your Personal Website" link="https://docs.google.com/document/d/1KJhR6nNv7nWVv2pFcORzwN7OUZhiLEoao4ubTlKzmD0/edit" mdxType="ExternalLink" />
                <ExternalLink type="project" name="Build Your Personal Website" link="https://docs.google.com/document/d/1KJhR6nNv7nWVv2pFcORzwN7OUZhiLEoao4ubTlKzmD0/edit" mdxType="ExternalLink" />
            </LinkBox>
            <LinkBox mdxType="LinkBox">
                <h1>Lectures / Codealongs</h1>
                <ExternalLink type="lecture" name="Lecture: Intro to Web Dev" link="https://docs.google.com/presentation/d/1DuogPxo7f6exIBeeS9C8KxWXaGFh4XPG3t3OFQExh7s/edit#slide=id.p" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Code Along: Intro to Web Dev" link="https://docs.google.com/document/d/1pqBF1la7YB-dTOPmaR2YEvUl1Z5amzBSsXFF7mVsfJ8/edit" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Lecture: Block vs Inline" link="https://docs.google.com/presentation/d/1XR5lRJEQAC2Qd91UU2Jegw8sa2k5g-NjlXOsgAli0aw/edit#slide=id.p" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Code Along: Block vs Inline" link="https://docs.google.com/document/d/14O68B441WD1q0jaPVFsjPSdpE1OXbOZUj8OSroTxGLc/edit" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Code Along: Github Hosting" link="https://docs.google.com/document/d/1oBCGKe315sY9M5Uwq2MO8QKbU3z2xZ46wTCb2HQKq08/edit" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Lecture: Box Model + Flexbox" link="https://docs.google.com/presentation/d/1FrkEB1Qdv6fC8polysEpOMjDoO8HXVrFug1wuvs1n54/edit#slide=id.p" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Code Along: Flexbox Froggy" link="https://docs.google.com/document/d/1kdzy9-6Ab-cvVKABsiEYl6hUYMpwK9GMP6tGTZL76p8/edit" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Code Along: Responsive Design" link="https://docs.google.com/document/d/1JvqNzpMIKRr-JZOQfyY3XbuDrP5nKlzjhidFL2M5Ncw/edit" mdxType="ExternalLink" />
            </LinkBox>
            <LinkBox mdxType="LinkBox">
                <h1>Core Skills</h1>
                {
              /* <ExternalLink type="" name="" link="" /> */
            }
            </LinkBox>
        </LinkContainer>
    </DayScheduleWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      